import React from "react";

export const AboutUs = (props: any) => {
  return (
    <div
      className="w-full min-h-screen bg-cover py-[10%] px-[10%] md:py-[5%] md:px-[10%] relative"
      style={{ backgroundImage: "url('/assets/bg/2_About.png')" }}
    >
      <h1 className="font-[900] text-[36px] leading-[36px]">关于我们</h1>

      <div className="flex flex-col-reverse md:flex-row">
        <p className="font-[400] text-[16px] mt-[50px] max-w-[auto] md:max-w-[420px]">
          CanvasLand 创元宇宙” 元宇宙咨询及制作公司，是
          “港科大南沙创科培育中心-粤港澳（国际）青年创新工场”
          的成员，致力为客户开发NFT及元宇宙， 如在元宇宙建立 “数字分身”
          、“数字礼宾司服务” 、“沉浸式虚拟工作空间”、“元宇宙游戏” 等。
          <br />
          <br />
          我们正在积极探索有实用意义的元宇宙方案，从而推进香港的网络3.0基础建设发展，并透过元宇宙的应用，提升企业为顾客所提供的服务以促进智慧城市转型。
          <br />
          <br />
          CanvasLand
          创元宇宙的团队由充满热诚的网络3.0先驱、元宇宙工程师及游戏开发人员组成，我們将帮助您设计别树一帜的元宇宙，成为未来虚拟世界经济的先驱。
          <br />
          <br />
          我们现有的顾客遍布各个行业，涉及金融机构、高等教育机构、房地产公司、餐饮、NFT
          项目等等。
        </p>

        {/* Image */}
        <img
          src="/assets/parts/about-img.png"
          alt="about-img"
          className="w-[full] mt-[50px] md:pl-[100px] md:mt-[-40px]"
        />
      </div>
    </div>
  );
};

import React from "react";

export const Video = (props: any) => {
  return (
    <div
      className="w-full min-h-screen bg-cover py-[10%] px-[0%] md:py-[5%] md:px-[10%] flex md:items-center md:justify-center"
      style={{ backgroundImage: "url('/assets/bg/4_Video.png')" }}
    >
      <video
        className="w-full object-cover"
        controls
        playsInline
        autoPlay={false}
      >
        <source
          src="https://video.wixstatic.com/video/a1e2a5_28177183d04841bf9eb64438614d4b90/1080p/mp4/file.mp4"
          type="video/mp4"
        />
      </video>
    </div>
  );
};

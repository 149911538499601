import React from "react";

export const ContactUs = (props: any) => {
  return (
    <div
      className="w-full min-h-screen bg-cover py-[10%] px-[10%] md:py-[5%] md:px-[10%] relative"
      style={{ backgroundImage: "url('/assets/bg/5_Contact.png')" }}
    >
      <div className="flex flex-col md:flex-row">
        <div className="w-fit h-fit relative">
          <h1 className="font-[900] text-[36px] leading-[36px] relative z-[5]">
            联络我们
          </h1>
          <img
            src="/assets/parts/title_element.png"
            alt="title_element"
            className="w-[100px] absolute right-[-40px] bottom-[-50px]"
          />
        </div>
        <div className="flex flex-1 flex-col md:items-end mt-[150px] md:mt-[0px]">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-[100px] md:gap-[50px] md:max-w-[70%]">
            {/* Office */}
            <div className="flex flex-col">
              <h2 className={$officeTitle}>办公室（广州）</h2>
              <p className={$officeLocation}>广州南沙创科培育中心</p>
            </div>
            <div className="flex flex-col">
              <h2 className={$officeTitle}>办公室（香港）</h2>
              <p className={$officeLocation}>
                九龙塘达之路72号创新中心1樓 Lion Rock 72，10室
              </p>
            </div>

            <a
              href="mailto:mila_canvasland@outlook.com?subject=元宇宙查询"
              className={`md:mt-[100px] font-[600] ${$officeTitle}`}
            >
              mila_canvasland@outlook.com
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const $officeTitle = "font-[900] text-[24px] leading-[24px]";
const $officeLocation = "font-[400] text-[24px] leading-[24px] mt-[30px]";

import React from "react";
import {
  Banner,
  Container,
  AboutUs,
  Products,
  Video,
  ContactUs,
} from "../components";

export const Home = (props: any) => {
  return (
    <Container noTopPadding={true}>
      {/* Banner */}
      <Banner />
      {/* About Us */}
      <AboutUs />
      {/* Products */}
      <Products />
      {/* Video */}
      <Video />
      {/* Contact Us */}
      <ContactUs />
    </Container>
  );
};

import React from "react";

export const Button = (props: any) => {
  const { txt = "立即报价", center } = props;
  return (
    <div
      className={`cursor-pointer w-[185px] h-[60px] bg-no-repeat ${
        center ? "mx-[auto]" : ""
      }`}
      style={{ backgroundImage: "url('/assets/parts/btn.png')" }}
      onClick={() => {
        window.location.href =
          "mailto:mila_canvasland@outlook.com?subject=元宇宙查询";
      }}
    >
      <p className="w-full text-center leading-[60px] text-[18px] font-[400]">
        {txt}
      </p>
    </div>
  );
};

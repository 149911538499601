import React from "react";
import { Product } from "./product";
import { Button } from "./button";

const products = [
  {
    imgUrl: "/assets/parts/p1.png",
    title: "元宇宙建设开发",
    desc: "元宇宙土地开发及兴建客制化 3D 建筑物",
  },
  {
    imgUrl: "/assets/parts/p2.png",
    title: "客制化3D模型",
    desc: "创建兼容 Decentraland, Spatial 及 Sandbox 等元宇宙的3D模型",
  },
  {
    imgUrl: "/assets/parts/p3.png",
    title: "NFT 及 智能合约开发",
    desc: "NFT设计及智能合约开发一站式服务",
  },
  {
    imgUrl: "/assets/parts/p4.png",
    title: "元宇宙游戏开发及应用",
    desc: "在元宇宙中加入客制化小游戏，既增加互动，亦提升用户体验",
  },
];

export const Products = (props: any) => {
  return (
    <div
      className="w-full min-h-screen bg-cover py-[10%] px-[10%] md:py-[5%] md:px-[10%] relative"
      style={{ backgroundImage: "url('/assets/bg/3_Products.png')" }}
    >
      <h1 className="font-[900] text-[36px] leading-[36px] text-center">
        产品及服务
      </h1>

      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 mt-[100px] gap-[50px]">
        {products.map((item, i) => {
          return <Product key={i} product={item} />;
        })}
      </div>

      <div className="mt-[100px]">
        <Button txt="了解更多" center />
      </div>
    </div>
  );
};

import React from "react";
import { Button } from "./button";

export const Banner = (props: any) => {
  return (
    <div
      className="w-full min-h-screen bg-cover py-[10%] px-[10%] md:py-[5%] md:px-[10%] relative"
      style={{ backgroundImage: "url('/assets/bg/1_Home.png')" }}
    >
      <img src="/assets/parts/logo.png" alt="logo" className="w-[300px]" />

      {/* Fade in Assets */}
      <div className="absolute w-[550px] h-[650px] bottom-[0px] md:bottom-[auto] top-[auto] md:top-[50%] mt-[auto] md:mt-[-325px] right-[-15%] md:right-[5%] left-[auto] z-[1px]">
        <img
          src="/assets/parts/prism.png"
          alt="prism"
          className="w-[160px] absolute left-[200px] top-[0px]"
        />
        <img
          src="/assets/parts/prism.png"
          alt="prism"
          className="w-[160px] absolute left-[auto] bottom-[0px] right-[0px]"
        />
        <img
          src="/assets/parts/door.png"
          alt="door"
          className="w-[400px] absolute top-[250px]"
        />
        <img
          src="/assets/parts/square.png"
          alt="square"
          className="w-[160px] absolute left-[50px] top-[250px]"
        />
      </div>

      {/* Description */}
      <div className="pt-[100px] md:pt-[150px] z-[5px] relative">
        <h1 className="font-[900] text-[78px] leading-[78px]">创元宇宙</h1>
        <h2 className="text-[31px] leading-[31px] font-[400] pt-[20px]">
          元宇宙咨询及制作公司
        </h2>
        <div className="mt-[50px]">
          <Button txt="立即报价" />
        </div>
      </div>
    </div>
  );
};

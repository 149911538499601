import React from "react";

export const Product = (props: any) => {
  const { product } = props;
  const { imgUrl, title, desc } = product;
  return (
    <div
      className="cursor-pointer w-[254px] h-[347px] bg-no-repeat mx-[auto]"
      style={{ backgroundImage: `url('${imgUrl}')` }}
    >
      <div className="px-[20px] pt-[250px]">
        <h2 className="text-[18px] leading-[18px] font-[900] text-[#fff] text-center ">
          {title}
        </h2>
        <p className="text-[12px] leading-[22px] font-[400] text-[#fff] text-center pt-[10px]">
          {desc}
        </p>
      </div>
    </div>
  );
};
